
import { defineComponent, PropType } from 'vue'
import { JobItem } from '@/data/dms/dmsData'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { useRouter } from 'vue-router'
import { convertPermission } from '@/helpers/util/dmsUtils'

export default defineComponent({
  name: 'JobItem',
  components: {
    DssIcon,
  },
  props: {
    jobItem: {
      type: Object as PropType<JobItem>,
    },
  },
  setup (props) {
    const router = useRouter()
    return {
      props,
      convertPermission,

      convertJtc (dateUtc: string) {
        let dateJtc = '-'
        if (dateUtc && dateUtc.includes('T') && dateUtc.includes('Z')) {
          const day = dateUtc.split('T')[0]
          const time = dateUtc.split('T')[1].replace('Z', '')
          const date =
            new Date(
              Number(day.split('-')[0]),
              Number(day.split('-')[1]) - 1,
              Number(day.split('-')[2]),
              Number(time.split(':')[0]),
              Number(time.split(':')[1]),
              Number(time.split(':')[2])
            )
          date.setHours(date.getHours() + 9)
          dateJtc =
            date.getFullYear() + '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
            ('0' + (date.getDate())).slice(-2) + ' ' +
            ('0' + (date.getHours())).slice(-2) + ':' +
            ('0' + (date.getMinutes())).slice(-2) + ':' +
            ('0' + (date.getSeconds())).slice(-2)
        }
        return dateJtc
      },
      async toItem (pid: string) {
        const pidNum = pid.split('/')[2]
        await router.push(`/dms/outer/item/${pidNum}`)
      },
    }
  },
})
