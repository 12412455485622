
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import JobInfoVue from '@/components/organisms/dms/OuterDetail/JobInfo.vue'
import JobItemVue from '@/components/organisms/dms/OuterDetail/JobItem.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { JobInfo, JobItem } from '@/data/dms/dmsData'
import { getDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterDetail',
  components: {
    JobInfoVue,
    JobItemVue,
    DssIcon,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const collectionDate = ref('')
    const jobId = route.params.id
    const jobInfo = ref<JobInfo>({
      id: '',
      startAt: '',
      endAt: '',
      status: '',
      successItemCount: '',
      errorItemCount: '',
    })
    const jobItem = ref<JobItem>({
      successItem: [],
      errorItem: [],
    })

    const convertDate = (date: string) => {
      return date ? date.replace('/', '年')
        .replace('/', '月')
        .replace(' ', '日 ')
        .replace(':', '時')
        .split(':')[0] + '分' : '-'
    }
    const getJobDetail = async () => {
      const url = `${BASE_URL}/outer/job/sent/detail?jobId=${jobId}`
      const response = await getDataCredential(url)

      const successItem = response.data.outerSentItemList
      const errorItem = response.data.outerFailedContentsList

      collectionDate.value = convertDate(response.data.startAt)
      jobItem.value = {
        successItem: successItem,
        errorItem: errorItem,
      }
      jobInfo.value = {
        id: response.data.jobId,
        startAt: response.data.startAt,
        endAt: response.data.endAt,
        status: response.data.status,
        successItemCount: response.data.successItemCount,
        errorItemCount: response.data.errorItemCount,
      }
    }
    getJobDetail()

    return {
      router,
      collectionDate,
      jobInfo,
      jobItem,
    }
  },
})
