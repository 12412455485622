
import { defineComponent, PropType } from 'vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { JobInfo } from '@/data/dms/dmsData'
import { convertStatus } from '@/helpers/util/dmsUtils'

export default defineComponent({
  name: 'JobInfo',
  components: {
    DssIcon,
  },
  props: {
    jobInfo: {
      type: Object as PropType<JobInfo>,
    },
  },
  setup (props) {
    const splitDate = (date: string) => {
      return date ? date.split('.')[0] : '-'
    }
    const convertNull = (data: any) => {
      return data || '-'
    }
    return {
      props,
      convertStatus,
      splitDate,
      convertNull,

      async download () {
        console.log('download')
      },
    }
  },
})
