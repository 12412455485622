
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import OuterDetail from '@/components/organisms/dms/OuterDetail/OuterDetail.vue'
export default defineComponent({
  components: {
    OuterDetail,
  },
  setup () {
    const router = useRouter()
    return {
      router,
    }
  },
})
